<template>
  <li 
    class="flex items-center border-b"
    :class="[ borderColor ]"
  >
    <a 
      href="https://www.raditube.com" 
      target="_blank"
    >
      <span 
        class="font-mono text-base py-3 px-2 block"
        :class="[ textColor ]"
      >
        View Best Practices
      </span>
    </a>
  </li>
</template>

<script>
  export default {
    props: {
      borderColor:{
        type: String,
        default: 'border-black'
      },
      textColor:{
        type: String,
        default: 'text-black'
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>