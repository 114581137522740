import { render, staticRenderFns } from "./ViewBestPractices.vue?vue&type=template&id=79e4d202&scoped=true&"
import script from "./ViewBestPractices.vue?vue&type=script&lang=js&"
export * from "./ViewBestPractices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e4d202",
  null
  
)

export default component.exports