<template>
  <div class="min-h-screen">
    <div class="h-12 w-full border-b"></div>
    <div class="pt-44 flex flex-wrap">
      <div class="w-1/5"></div>
      <div class="w-2/4 px-4">
        <p class="text-white font-bold head-title leading-none mb-7">Discovery</p>
        <p class="text-2xl text-white leading-8 mb-16">
          The RadiTube Discovery tool lets you discover content watched by similar audiences. 
        </p>
        <router-link 
          to="/discovery"
          class="font-monoDemi inline-block text-base font-grey-darker-500 leadint-none yellow-bg rounded-2xl px-5 py-2 mb-14"
        >
          Start a New Discovery
        </router-link>

        <p class="text-white text-xl font-sansDemi border-grey-light-200 border-b pb-3">
          Or view the results of your earlier discoveries
        </p>
        <ul>
          <li class="flex items-center justify-between border-grey-light-200 border-b">
            <span class="w-4/5 text-base py-3 pr-4 base-font-gray-light-300">
              Example (not working yet)
            </span>
            <span class="w-1/5 font-mono text-sm w-1/4 py-3 pr-3 base-font-gray-light-400">30 days ago</span>
          </li>
        </ul>
      </div>
      <div class="w-1/5 px-4">
        <ul class="mt-44 border-white border-t">
          <ViewBestPractices 
            borderColor="border-white"
            textColor="base-font-gray-light-300"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ViewBestPractices from '@/components/ViewBestPractices'

export default {
  name: 'Discovery',
  components:{
    ViewBestPractices
  },
};
</script>

<style>
</style>